import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faCog, fas, faUserCog, faEnvelope, faHome, faUsers, faSearchPlus, faHandHoldingMedical, faMedkit, faPowerOff, faUserPlus, faPlusCircle, faStethoscope, faBookMedical, faTimes, faCheckCircle, faLongArrowAltLeft, faComment, faPaperPlane, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import router from './router';
import VueSplide from '@splidejs/vue-splide';
// import vueSocialAuth from 'vue-social-auth';

const myApp = createApp(App);
myApp.component('v-select', vSelect)
myApp.use(VueSweetalert2);
library.add(faUserSecret, faCog, fas, faUserCog, faEnvelope, faHome, faUsers, faSearchPlus, faHandHoldingMedical, faMedkit, faPowerOff, faUserPlus, faPlusCircle, faStethoscope, faBookMedical, faTimes, faCheckCircle, faLongArrowAltLeft, faComment, faPaperPlane, faEdit);
myApp.component('font-awesome-icon', FontAwesomeIcon)
myApp.config.productionTip = false
myApp.use(router);
myApp.mount('#app');
myApp.use( VueSplide );
